import { lazy } from "react";

import { ROLES } from "../Utils/roleAndPermUtils";
import i18n from "../i18n";
import * as myRouters from "../Utils/routerConstants";

const BackendLayout = lazy(() => import("../Backend/Layout/BackendLayout"));
const DashboardPage = lazy(() =>
  import("../Backend/Pages/Dashboard/DashboardPage")
);
const ProfilePage = lazy(() => import("../Backend/Pages/Profile/ProfilePage"));
const ChangePasswordPage = lazy(() =>
  import("../Backend/Pages/Account/ChangePassword/ChangePasswordPage")
);
const HistoryLogPage = lazy(() =>
  import("../Backend/Pages/HistoryLog/HistoryLogPage")
);

const NotAuthorizationPage = lazy(() =>
  import("../Backend/Pages/NotAuthorization/NotAuthorizationPage")
);
const WelcomePage = lazy(() =>
  import("../Backend/Pages/NotAuthorization/WelcomePage")
);

// COURSE
const CourseDashboardPage = lazy(() =>
  import("../Backend/Pages/Course/CourseDashboardPage")
);
// const CourseCategoryListPage = lazy(() =>
//   import("../Backend/Pages/Course/CourseCategory/CourseCategoryListPage")
// );
const CourseIndexListPage = lazy(() =>
  import("../Backend/Pages/Course/CourseIndex/CourseIndexListPage")
);
const CourseCommentListPage = lazy(() =>
  import("../Backend/Pages/Course/CourseComment/CourseCommentListPage")
);
const CourseRegisterWebinarPage = lazy(() =>
  import(
    "../Backend/Pages/Course/CourseRegisterWebinar/CourseRegisterWebinarPage"
  )
);
const CourseQuestionAndAnswerPage = lazy(() =>
  import(
    "../Backend/Pages/Course/CourseQuestionAndAnswer/CourseQuestionAndAnswerPage"
  )
);
const SchoolITInVietNamPage = lazy(() =>
  import("../Backend/Pages/Course/SchoolITInVietNam/SchoolITInVietNamPage")
);
const CompanyITInVietNamPage = lazy(() =>
  import("../Backend/Pages/Course/CompanyITInVietNam/CompanyITInVietNamPage")
);
const MarketITInVietNamPage = lazy(() =>
  import("../Backend/Pages/Course/MarketITInVietNam/MarketITInVietNamPage")
);
const MarketITInAbroadPage = lazy(() =>
  import("../Backend/Pages/Course/MarketITInAbroad/MarketITInAbroadPage")
);
// student with course
// const StudentIndexListPage = lazy(() =>
//   import("../Backend/Pages/Course/Student/StudentIndexListPage")
// );
const StudentSupportCourseIndexListPage = lazy(() =>
  import("../Backend/Pages/Course/Student/StudentSupportCourseIndexListPage")
);
const StudentSSSupportCourseIndexListPage = lazy(() =>
  import(
    "../Backend/Pages/Course/Student/StudentSecondarySchool/StudentSSSupportCourseIndexList"
  )
);
const StudentCourseLicensePage = lazy(() =>
  import(
    "../Backend/Pages/Course/Student/StudentCourseLicense/StudentCourseLicensePage"
  )
);
const MyNewHighSchoolStudentListPage = lazy(() =>
  import("../Backend/Pages/Student/StudentHighSchool/MyNewStudentListPage")
);
// const MyOldHighSchoolStudentListPage = lazy(() =>
//   import("../Backend/Pages/Student/StudentHighSchool/MyOldStudentListPage")
// );

// SOFTWARE CAREER ======================

// const LeaderSupportHighSchoolPage = lazy(() =>
//   import("../Backend/Pages/Course/Student/StudentHighSchool/LeaderSupportHighSchoolPage")
// );

//
const routers = [
  {
    path: myRouters.DASHBOARD_URL,
    name: i18n.t("Dashboard"),
    component: DashboardPage,
    layout: BackendLayout,
    exact: true,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.PROFILE_PAGE_URL,
    name: i18n.t("Profile"),
    component: ProfilePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.HISTORY_LOG_PAGE_URL,
    name: i18n.t("History log"),
    component: HistoryLogPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MANAGER],
  },
  {
    path: myRouters.CHANGE_PASSWORD_PAGE_URL,
    name: "Thay đổi mật khẩu",
    component: ChangePasswordPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // COURSE
  {
    path: myRouters.COURSE_DASHBOARD_PAGE_URL,
    name: "Course - Bảng điều khiển",
    component: CourseDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  // {
  //   path: myRouters.COURSE_CATEGORY_LIST_PAGE_URL,
  //   name: "Danh mục course",
  //   component: CourseCategoryListPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.ADMIN],
  // },
  {
    path:
      myRouters.COURSE_INDEX_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name",
    name: "Mục lục course",
    component: CourseIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path:
      myRouters.COURSE_COMMENT_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name",
    name: "Comment course",
    component: CourseCommentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COURSE_CONTACT_WEBINAR_LIST_PAGE_URL,
    name: "Webinar liên hệ",
    component: CourseRegisterWebinarPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COURSE_QUESTION_LIST_PAGE_URL,
    name: "Học viên hỏi đáp",
    component: CourseQuestionAndAnswerPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SCHOOL_IT_IN_VIETNAM_LIST_PAGE_URL,
    name: "Trường IT VietNam",
    component: SchoolITInVietNamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COMPANY_IT_IN_VIETNAM_LIST_PAGE_URL,
    name: "Company IT VietNam",
    component: CompanyITInVietNamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.MARKET_IT_IN_VIETNAM_LIST_PAGE_URL,
    name: "Market IT VietNam",
    component: MarketITInVietNamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.MARKET_IT_IN_ABROAD_LIST_PAGE_URL,
    name: "Market IT tại nước ngoài",
    component: MarketITInAbroadPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  // STUDENT WITH COURSE
  // {
  //   path: myRouters.COURSE_STUDENT_INDEX_LIST_PAGE_URL,
  //   name: "Danh sách học viên",
  //   component: StudentIndexListPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.ADMIN],
  // },
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_HS_INDEX_LIST_PAGE_URL,
    name: "Hỗ trợ bài giảng HS",
    component: StudentSupportCourseIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_HS_NEW_INDEX_LIST_PAGE_URL,
    name: "Học viên HS MỚI",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  // {
  //   path: myRouters.COURSE_SUPPORT_STUDENT_HS_OLD_INDEX_LIST_PAGE_URL,
  //   name: "Học viên HS CŨ",
  //   component: MyOldHighSchoolStudentListPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.ADMIN, ROLES.MEMBER],
  // },
  // {
  //   path: myRouters.COURSE_SUPPORT_LEADER_SUPPORT_HS_INDEX_LIST_PAGE_URL,
  //   name: "Leader hỗ trợ HS",
  //   component: LeaderSupportHighSchoolPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.ADMIN, ROLES.MEMBER],
  // },

  // ==================================
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_SS_INDEX_LIST_PAGE_URL,
    name: "Hỗ trợ bài giảng SS",
    component: StudentSSSupportCourseIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_LICENSE_LIST_PAGE_URL +
      "/:student_me_id/:student_me_info",
    name: "Cập nhật chứng nhận, chứng chỉ",
    component: StudentCourseLicensePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // Authentication
  {
    path: myRouters.NO_PERMISSION_URL,
    name: "Bạn không được phép ở trang này!",
    component: NotAuthorizationPage,
    layout: BackendLayout,
  },
  {
    path: myRouters.WELCOME_URL,
    name: "CHÚC MỘT NGÀY TỐT LÀNH!",
    component: WelcomePage,
    layout: BackendLayout,
  },
];

export default routers;
